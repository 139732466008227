<script lang="ts" setup>
import { useModal } from "vue-final-modal";
import ClassroomAssignmentSummaryModal from "./SummaryModal.vue";

const props = defineProps({
  classroomId: {
    type: String,
    required: true,
  },
  assignmentId: {
    type: String,
    required: true,
  },
  alwaysShow: {
    type: Boolean,
    default: false,
  },
});

const assignmentDataStore = useAssignmentDataStore(props.assignmentId);

const { assignment, ungradedFinalDrafts, numGradedFinalDraftDocuments } =
  storeToRefs(assignmentDataStore);

const uid = useCurrentUID();

const classroomDataStore = useClassroomDataStore(uid!, props.classroomId);

const { students } = storeToRefs(classroomDataStore);

const numSubmittedDocuments = computed(() => {
  return ungradedFinalDrafts.value.length + numGradedFinalDraftDocuments.value;
});

const numStudents = computed(() => {
  return students.value.length;
});

const showSummary = () => {
  const { open, close } = useModal({
    component: ClassroomAssignmentSummaryModal,
    attrs: {
      classroomId: props.classroomId,
      assignmentId: props.assignmentId,
      onClose: () => {
        close();
      },
    },
  });

  open();
};
</script>

<template>
  <BaseMultiProgressBar
    v-if="
      alwaysShow ||
      numSubmittedDocuments > 0 ||
      numGradedFinalDraftDocuments > 0
    "
    :key="
      numSubmittedDocuments + numGradedFinalDraftDocuments + students.length
    "
    :max-value="numStudents"
    :primary-value="numSubmittedDocuments"
    :primary-tooltip="`${numSubmittedDocuments} / ${students.length} submitted`"
    :secondary-value="numGradedFinalDraftDocuments"
    :secondary-tooltip="`${numGradedFinalDraftDocuments} / ${students.length} graded`"
    class="w-full my-2 cursor-pointer"
    @click="showSummary"
  />
</template>

<style scoped></style>
